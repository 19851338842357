import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



function Applicants() {
  const [pdfList, setPdfList] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.gaprotectivegroup.com}/api/list-pdfs");
        if (response.ok) {
          const data = await response.json();
          setPdfList(data);
        } else {
          console.error('Failed to fetch PDF list');
        }
      } catch (error) {
        console.error('Error fetching PDF list:', error);
      }
    };

    fetchData();
  }, []);

  const handleSignOut = () => {
    // Clear user token and other relevant data from local storage
    localStorage.removeItem("user");
  
    // Optionally, reset any global state used to track login status
    // This might involve lifting state up or using a context or state management library
    // For simplicity, we'll navigate to the login page which should check authentication status
    
    navigate('/login'); // Navigate to the login page after sign out
  };
  

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Applications</h1>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {pdfList.map(pdf => (
          <li key={pdf.name} style={{ background: '#fff', padding: '10px', margin: '10px 0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <a href={`https://api.gaprotectivegroup.com/api/saved-pdfs/${pdf.name}`} download style={{ textDecoration: 'none', color: '#007bff' }}>{pdf.name}</a>
          </li>
        ))}
      </ul>
      <button onClick={handleSignOut} className="sign-out-button">Sign Out</button> {/* Add this line */}
    </div>
  );
}

export default Applicants;
