import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home';
import Login from './login';
import Applicants from './Applicants';
import './App.css';
import { useEffect, useState } from 'react';
// Import the Navigate component for redirection
import { Navigate } from 'react-router-dom';
// Define the PrivateRoute component
const PrivateRoute = ({ loggedIn, children }) => {
    return loggedIn ? children : <Navigate to="/login" replace />;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")
 

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
  
    if (!user || !user.token) {
      setLoggedIn(false);
      return;
    }
  
    fetch("https://api.gaprotectivegroup.com/verify", {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      if (response.ok) {
        // If the token verification is successful
        setLoggedIn(true);
        setEmail(user.email);
      } else {
        // If the token is invalid or expired
        throw new Error('Token validation failed');
      }
    })
    .catch(error => {
      console.error('Verification error:', error);
      localStorage.removeItem("user"); // Clear stored user info
      setLoggedIn(false);
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
          <Route path="/applicants" element={
            <PrivateRoute loggedIn={loggedIn}>
              <Applicants />
            </PrivateRoute>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
