import React, { useState, useEffect } from 'react';
import logo from './logo.svg'; // Make sure the path to your logo is correct

function InterestForm() {
  const [formData, setFormData] = useState({
    // Initial form state
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    positionAppliedFor: '',
    authorizedToWorkInUS: false,
    desiredHourlyRate: '',
    employmentType: '', // Full-time or Part-time
    flexibleWithShiftWork: false,
    convicted: false,
    validDriversLicense: false,
    reliableTransportation: false,
    employmentHistory: '',
    educationalBackground: '',
    references: '',
  });
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Toggle the 'no-scroll' and 'blur' classes on the body
    document.body.classList.toggle('no-scroll', showModal);
    document.body.classList.toggle('blur-background', showModal);
  }, [showModal]);

  const handleChange = (e) => {
    // Form change handler
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    // Form submit handler
    e.preventDefault();
    try {
      // Attempt to save PDF or perform desired action
      const response = await fetch('https://api.gaprotectivegroup.com/api/save-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('PDF saved successfully');
        setShowModal(true); // Show success modal
      } else {
        console.error('Error saving PDF');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const closeModalAndRefresh = () => {
    // Close modal and refresh page
    setShowModal(false);
    window.location.reload();
  };


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {showModal && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 100, padding: '20px', background: 'white', borderRadius: '10px' }}>
          <h2>Thank You for Your Interest!</h2>
          <p>Your application has been received.</p>
          <button onClick={closeModalAndRefresh}>Close</button>
        </div>
      )}

      <div className="content" style={{ width: '100%', maxWidth: '600px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto', margin: '20px' }} />
        <form onSubmit={handleSubmit}>
        
    
      <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" />
      <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" />
      <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" />
      <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email Address" />
      <input type="text" name="positionAppliedFor" value={formData.positionAppliedFor} onChange={handleChange} placeholder="Position Applied For" />
      <label>
        Authorized to work in the US:
        <input type="checkbox" name="authorizedToWorkInUS" checked={formData.authorizedToWorkInUS} onChange={handleChange} />
      </label>
      <input type="text" name="desiredHourlyRate" value={formData.desiredHourlyRate} onChange={handleChange} placeholder="Desired Hourly Pay" />
      <select name="employmentType" value={formData.employmentType} onChange={handleChange}>
        <option value="">Select Employment Type</option>
        <option value="full-time">Full-time</option>
        <option value="part-time">Part-time</option>
      </select>
      <label>
        Flexible with all shift work:
        <input type="checkbox" name="flexibleWithShiftWork" checked={formData.flexibleWithShiftWork} onChange={handleChange} />
      </label>
      <label>
        Convicted of a misdemeanor or felony:
        <input type="checkbox" name="convicted" checked={formData.convicted} onChange={handleChange} />
      </label>
      <label>
        Valid driver's license:
        <input type="checkbox" name="validDriversLicense" checked={formData.validDriversLicense} onChange={handleChange} />
      </label>
      <label>
        Reliable transportation:
        <input type="checkbox" name="reliableTransportation" checked={formData.reliableTransportation} onChange={handleChange} />
      </label>
      <textarea name="employmentHistory" value={formData.employmentHistory} onChange={handleChange} placeholder="Employment History"></textarea>
      <textarea name="educationalBackground" value={formData.educationalBackground} onChange={handleChange} placeholder="Educational Background"></textarea>
      <textarea name="references" value={formData.references} onChange={handleChange} placeholder="References"></textarea>
      <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default InterestForm;
