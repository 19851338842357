import React from "react"
import { useNavigate } from "react-router-dom";
import InterestForm from "./InterestForm";

const Home = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate();

    
    const onButtonClick = () => {
        if (loggedIn) {
            localStorage.removeItem("user")
            props.setLoggedIn(false)
        } else {
            navigate("/login")
        }
    }
    const navigateToApplicants = () => {
        navigate("/applicants"); // Assuming "/applicants" is the route to view applicants
    };

    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
       
            <InterestForm />
        
        <div className={"buttonContainer"}>
            <input
                className={"inputButton"}
                type="button"
                onClick={onButtonClick}
                value={loggedIn ? "Log out" : "Log in"} />
            {(loggedIn ? <div>
                Your email address is {email}
            </div> : <div/>)}
            {loggedIn && (
                    <div>
                        
                        <input
                            className={"inputButton"}
                            type="button"
                            onClick={navigateToApplicants}
                            value="Applicants" />
                    </div>
                )}
        </div>


    </div>
}

export default Home